import DocumentTitle from 'react-document-title';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from 'react';
import URLSearchParams from 'url-search-params';
import axios from 'axios';
import { format } from 'date-fns';
import './invoice.css';
import Grid from '@material-ui/core/Grid';
import { reduce } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import { Loader } from './newStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Invoice extends Component {
  state = {loading: true, data: {}, totals: {}, error: null};
  formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null
  };
  constructor(props) {
    super(props);
    this.pp = React.createRef();
  }
  formatCurrency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };
  getLaborItems = (laborItems) => {
    return laborItems.map(laborItem => {
      return <TableRow key={laborItem.id}>
        <TableCell colSpan={2}>{laborItem.description}</TableCell>
        <TableCell numeric>${this.formatCurrency(laborItem.cost)}</TableCell>
      </TableRow>
    });
  };
  getMaterialItems = (materialItems) => {
    return materialItems.map(materialItem => {
      return <TableRow key={materialItem.id}>
        <TableCell>{materialItem.quantity}</TableCell>
        <TableCell>{materialItem.description}</TableCell>
        <TableCell numeric>${this.formatCurrency(materialItem.unitCost)}</TableCell>
        <TableCell numeric>${this.formatCurrency(materialItem.unitCost * materialItem.quantity)}</TableCell>
      </TableRow>
    });
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('authCode');
    const invoiceId = this.props.match.params.invoiceId;
    const url = 'https://api.hyperxi.com';
    const path = `${url}/invoices/view/${invoiceId}?authCode=${authCode}`;
    let response;
    try {
      response = await axios.get(path);
      this.setState({
        loading: false,
        data: response.data,
        totals: this.computeTotals(response.data.invoiceLaborItems, response.data.invoiceMaterialItems, response.data.offset)
      }, () => {
        const total = this.state.totals.total;
        const id = this.state.data.id;
        const description = this.state.data.description;
        window.paypal.Buttons({
            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        reference_id: id,
                        description: description,
                        invoice_number: id,
                        amount: {
                            value: total
                        }
                    }]
                });
            },
            onApprove: function(data, actions) {
                return actions.order.capture().then(function(details) {
                    alert('Transaction completed by ' + details.payer.name.given_name);
                });
            }
        }).render(this.pp.current);
      });
    }
    catch (error) {
      if (error.response) { // [todo] Send UI notice here
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        this.setState({error: error.response.data.message});
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  }

  computeTotals(laborItems, materialItems, offset) {
    if (!offset) {
      offset = 0;
    }
    const totals = {
      labor: 0,
      materials: 0,
      grandTotal: 0,
      total: 0
    };

    totals.labor = reduce(laborItems, (sum, laborItem) => {
      return laborItem.cost.toFixed(2) * 1 + sum;
    }, 0);

    totals.materials = reduce(materialItems, (sum, materialItem) => {
      return (materialItem.unitCost.toFixed(2) * 1 * materialItem.quantity) + sum;
    }, 0);

    totals.grandTotal = totals.labor + totals.materials;
    totals.total = totals.grandTotal + offset;
    return totals;
  };

  render() {
    return (
      <DocumentTitle title={`creativeFEW Invoice #${this.state.data.id}`}>
        <React.Fragment>
          <CssBaseline/>
          {this.state.loading && !this.state.error && <Loader><CircularProgress size={80} color="primary"/></Loader>}
          {this.state.error && <div>{this.state.error}</div>}
          {!this.state.loading && !this.state.error &&
          <React.Fragment>
            <div id="masterContainer">
              <div id="header">
                <Typography align="right" component="h1" variant="h2" gutterBottom>INVOICE</Typography>
                <div id="invoiceInfo">
                  Invoice # {this.state.data.id}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;EIN #
                  46-3802232&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{format(this.state.data.issuedAt, 'MM/DD/YYYY')}
                  <div style={{textAlign: 'right'}}>Terms: Net {this.state.data.netTerms}</div>
                </div>
              </div>
              <div id="headerC1">
                <a href="http://creativeacceleration.com"><img
                  src="https://res.cloudinary.com/creativefew-inc/image/upload/c_scale,w_395/v1493312834/creativefew_internal/CF-Logo_yuphzh.png"
                  alt="creativefew logo"/></a>
              </div>
              <div id="headerC2">
                <div id="clientCaption">
                  CLIENT INFORMATION
                </div>
                <div id="clientC1">
                  <strong>Company</strong><br/>
                  {this.state.data.user.companyName}<br/>
                  {this.state.data.user.address}<br/>
                  {this.state.data.user.city}, {this.state.data.user.state} {this.state.data.user.zip}<br/>
                  <a href={this.state.data.user.url} rel="noopener noreferrer"
                     target="_blank">{this.state.data.user.url}</a>
                </div>
                <div id="clientC2">
                  <strong>Contact</strong><br/>
                  {this.state.data.user.firstName} {this.state.data.user.lastName}<br/>
                  {this.formatPhoneNumber(this.state.data.user.phone)}<br/>
                  <a href={'mailto:' + this.state.data.user.email}>{this.state.data.user.email}</a>
                </div>
              </div>
              <div id="projectInfo">
                <Typography component="h2" variant="h4" gutterBottom>Project</Typography>
                <Paper style={{marginBottom: '20px', padding: '20px'}}>
                  <Typography component="h3" variant="h5" gutterBottom>{this.state.data.description}</Typography>
                  <Typography component="div" variant="body1" gutterBottom>Job
                    Dates: {this.state.data.dateRange}</Typography>
                  <Typography component="div" variant="body1">ATTN/PO# <span style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '0.2em',
                    fontWeight: 'bold'
                  }}>{this.state.data.po ? this.state.data.po : 'Not Supplied'}</span></Typography>
                </Paper>
              </div>
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <Typography component="h2" variant="h4" gutterBottom>Labor</Typography>
                  <Paper style={{marginBottom: '20px'}}>
                    <Table padding="dense">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>Description</TableCell>
                          <TableCell numeric>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.getLaborItems(this.state.data.invoiceLaborItems)}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={3} numeric>Total:
                            ${this.formatCurrency(this.state.totals.labor)}</TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="h2" variant="h4" gutterBottom>Materials</Typography>
                  <Paper style={{marginBottom: '20px'}}>
                    <Table padding="dense">
                      <TableHead>
                        <TableRow>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell numeric>Unit Cost</TableCell>
                          <TableCell numeric>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.getMaterialItems(this.state.data.invoiceMaterialItems)}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={4} numeric>Total:
                            ${this.formatCurrency(this.state.totals.materials)}</TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
              <Typography component="h1" variant="h4" gutterBottom>Summary</Typography>
              <Paper>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Labor</TableCell>
                      <TableCell numeric>${this.formatCurrency(this.state.totals.labor)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Materials</TableCell>
                      <TableCell numeric>${this.formatCurrency(this.state.totals.materials)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Grand Total</TableCell>
                      <TableCell numeric>${this.formatCurrency(this.state.totals.grandTotal)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Offset/Deposit</TableCell>
                      <TableCell numeric>${this.formatCurrency(this.state.data.offset)}</TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} numeric><Typography component="span" variant="h5" gutterBottom>Total:
                        ${this.formatCurrency(this.state.totals.total)}</Typography></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
              <div id="paymentInfoTitle">
                Please remit payment to:
              </div>
              <div id="paymentInfo">
                creativeFEW, Inc.<br/>
                22535 SW Chilkat Ter.<br/>
                Tualatin, OR 97062<br/>
                <a
                  href={`//www.creativefew.com/invoice/${this.state.data.id}?authCode=${this.state.data.user.authCode}`}>Invoice
                  #{this.state.data.id}</a>
              </div>
              <div className="no-print" style={{width: '100px'}} ref={this.pp}/>
            </div>
          </React.Fragment>
          }
        </React.Fragment>
      </DocumentTitle>
    )
  }
}