import React from 'react';
import styled from 'styled-components';
import DocumentTitle from 'react-document-title';

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default () => <DocumentTitle title="creativeFEW, Inc."><Wrapper>
    <img width="300" src="https://res.cloudinary.com/creativefew-inc/image/upload/c_scale,w_800/v1493312834/creativefew_internal/CF-Logo_yuphzh.png" alt="creativeFEW Logo"/>
</Wrapper></DocumentTitle>