import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
import Invoice from './invoice/invoice';
import './App.css';

class App extends Component {
  render() {
    return (
        <Router>
            <section>
                <Route exact path="/" component={Home}/>
                <Route path="/invoice/:invoiceId" component={Invoice} />
            </section>
        </Router>
    );
  }
}

export default App;
